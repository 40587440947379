<!--begin::Form-->
<form [formGroup]="addressForm" class="form form-group-seperator-dashed">

	<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
		Oh snap! Change a few things up and try submitting again.
	</kt-alert>
	<div class="form-section form-section-first">
		<div class="form-group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter Address Line" formControlName="addressLine" />
					<mat-error>Address Line is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Address Line</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="City" formControlName="city" />
					<mat-error>City is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>City</strong>
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter State Line" formControlName="state" />
					<mat-error>State is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>State</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter Postode" formControlName="postCode" />
					<mat-error>Postcode is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Postcode</strong>
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
	</div>
</form>
<!--end::Form-->
